import $ from 'jquery';
import Swiper from 'swiper';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';

import 'swiper/swiper.scss';  
//import 'swiper/modules/pagination.scss'; 
import 'swiper/modules/navigation.scss'; 

import './../../scss/blocks/block-banner.scss';

$(document).ready(function() {

  var slides = document.getElementsByClassName("banner-swiper");

  for (var i = 0; i < slides.length; i++) {
    //Distribute(slides.item(i));
    var bannerswiper = new Swiper(slides.item(i), {
      modules: [Navigation, Autoplay],
      spaceBetween: 10,
      loop: true,
      slidesPerView: 1,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: slides.item(i).getElementsByClassName('banner-swiper-button-next').item(0),
        prevEl: slides.item(i).getElementsByClassName('banner-swiper-button-prev').item(0),
      },
    });  
  }
  
  

})